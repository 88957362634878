import React from 'react';
import { bool, oneOfType, object, string, shape, func } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { IconSuccess, NamedLink, NamedRedirect } from '../../components';
import EditIcon from './EditIcon';

import css from './ListingPage.module.css';
import { ArrowLeftOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../util/routes';

export const ListingApprovalActionBarMaybe = props => {
  const {
    rootClassName,
    className,
    isOwnListing,
    listing,
    editParams,
    showNoPayoutDetailsSet,
    currentUser,
    approveListing,
    history
  } = props;
  const handleApproveListing = (listingId) => {
    return approveListing(listingId).then(res => {
      history?.push(
        createResourceLocatorString(
          'AdminListingPage',
          routeConfiguration,
          {}
        )
      );
      message.success('Listing is approved Successfully');
    })
  }
  const routeConfiguration = useRouteConfiguration();

  const classes = classNames(rootClassName || css.actionBar, className);
  var isAdmin = currentUser?.attributes?.profile?.publicData?.isAdmin

  const state = listing.attributes.state;
  const listingId = listing.id.uuid;
  const isApprovedListingUpdate = listing.attributes.publicData.isApprovedListingUpdate
  if (isAdmin && isApprovedListingUpdate == false) {
    return (
      <div className={classes}>
        <NamedLink className={css.addPayoutDetails} name="AdminListingPage" >
          <Button className={css.buttonMarketplace} >
            <ArrowLeftOutlined></ArrowLeftOutlined>
            <FormattedMessage id="LimitedAccessBanner.back" />
          </Button>
        </NamedLink>
        <p className={classNames(css.ownListingText, css.missingPayoutDetailsText)}>
          <p className={css.text}>
            <FormattedMessage id="LimitedAccessBanner.listingApprove" />
          </p>
        </p>
        <span className={css.addPayoutDetails} >
          <Button type="primary" className={css.buttonMarketplace} onClick={() => {
            handleApproveListing(listingId)
          }
          } ><CheckCircleFilled></CheckCircleFilled> <FormattedMessage id="LimitedAccessBanner.approve" /></Button>
        </span>
      </div >
    )
  }
  return null;
};
ListingApprovalActionBarMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  approveListing: null
};

ListingApprovalActionBarMaybe.propTypes = {
  rootClassName: string,
  className: string,
  isOwnListing: bool.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  editParams: shape({
    id: string,
    slug: string,
    type: string,
    tab: string,
  }),
  currentUser: string,
  approveListing: func.isRequired,
};

ListingApprovalActionBarMaybe.displayName = 'ListingApprovalActionBarMaybe';

export default ListingApprovalActionBarMaybe;
